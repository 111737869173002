<template>
    <div>
        <div class="main-title">直播课程</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">创建课程后会自动同步到class in后台</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-input clearable placeholder="请输入课程名称" v-model="searchName" />
                </span>


                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <el-button type="primary" size="small" @click="showDialog" >新增课程</el-button>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="课程名称" >
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="封面"  >
                        <template slot-scope="scope">
                            <el-image
                                    style="width: 120px; height: 80px"
                                    :preview-src-list="[imageUrl + scope.row.pic]"
                                    :src="imageUrl + scope.row.pic"
                                    fit="cover"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="班主任">
                        <template slot-scope="scope">
                            <span>{{scope.row.teacher}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="状态">
                        <template slot-scope="scope">
                            <el-switch
                                    v-model="scope.row.status"
                                    :active-value="1"
                                    :inactive-value="0"
                                    @change="setListStatus(scope.row)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间" >
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="创建人" >
                        <template slot-scope="scope">
                            <span>{{scope.row.user_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link type="primary" @click="editCourse(scope.row)">编辑</el-link>
                                -
                                <el-button
                                        size="mini"
                                        type="text"
                                        @click="
									$router.push({
										path: '/liveCourseClass/'+scope.row.course_id,
									})
								">详情</el-button>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog
                title="设置课程"
                :show-close="false"
                :close-on-click-modal="false"
                append-to-body
                :visible.sync="dialogVisible"
                width="400px">
            <div>
                <el-form :rules="rules"  ref="form" :model="form" label-width="110px">
                    <el-form-item label="课程名称：" prop="name">
                        <el-input clearable size="mini" v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="课程封面：" prop="pic">
                        <select-images
                                @onSelect="onSelectFile"
                                @onDelete="onDelete"
                                :image="image"
                        ></select-images>
                    </el-form-item>
                    <el-form-item label="班主任：" prop="teacherUid">
                        <el-select clearable size="mini" style="width: 100%" v-model="form.teacherUid" placeholder="请选择班主任">
                            <el-option v-for="item in teachers" :key="item.id" :label="item.realname" :value="item.uid" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideDialog">取 消</el-button>
            <el-button size="mini" type="primary" @click="addLiveCourse" :loading="submitLoading">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectImages from "../../../components/selectImages/index";

    export default {
        name: "template-list",
        components: {SelectImages},
        data(){
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    pic: [
                        { required: true, message: '请选择图片', trigger: 'blur' },
                    ]
                },
                image:'',
                searchName:'',
                dialogVisible:false,
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                teachers: [],
                form:{
                    id:'',
                    name:'',
                    liveTrialId:0,
                    pic:'',
                    teacherUid:'',
                },
                submitLoading:false,
            }
        },
        methods:{
            ...mapActions('live',['setCourse','getLiveUser','getLiveCourseList']),
            ...mapActions('common',['setStatus']),
            showDialog(){
                this.dialogVisible = true
            },
            hideDialog(){
                this.dialogVisible = false
            },
            setListStatus({ status, id }) {
                this.setStatus({
                    model: "LiveCourse\\LiveCourseModel",
                    id,
                    status,
                }).then(res => {
                    if(res.ret === 0){
                        this.$message.success('设置成功')
                        this.getList()
                    }
                })
            },
            onSelectFile(image){
                this.image = imageUrl + image.path;
                this.form.pic = image.path;
                console.log(this.form)
            },
            onDelete() {
                this.image = "";
            },
            editCourse(row){
                this.showDialog()
                this.form.id = row.id
                this.form.name = row.name
                this.form.pic = row.pic
                this.image = imageUrl + this.form.pic
                this.form.teacherUid = row.teacher_uid
                console.log(row)
            },
            addLiveCourse(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        console.log(this.form)
                        this.submitLoading = true
                        this.setCourse(this.form).then(res => {
                            if(res.ret===0){
                                this.$message.success('添加成功！')
                                this.getList()
                                this.$refs['form'].resetFields()
                                this.hideDialog()
                            }
                        }).finally(() => {
                            this.submitLoading = false
                        })
                    }
                });
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    name:this.searchName,
                    page:this.page,
                    pageSize:this.pageSize,
                    liveTrialId:this.form.liveTrialId
                }
                this.getLiveCourseList(data).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                }).finally(() =>{
                    this.loading = false
                })

            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
        },
        mounted() {
            console.log()
            if(this.$route.params.id){
                this.form.liveTrialId = this.$route.params.id
            }
            this.getLiveUser({type:2,pageSize:2000}).then(res => {
                this.teachers = res.data.list
            })
            this.getList()
        }
    }
</script>

<style>

</style>
